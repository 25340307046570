.container {
  @apply fixed;
  @apply bottom-0 right-0;
  @apply md:bottom-[20px] md:right-[20px];
  @apply bg-white;
  @apply text-dark;
  @apply p-6 md:p-12;
  @apply z-[9999999];
  @apply max-w-[unset] md:max-w-[550px];

  p {
    @apply font-bold;
    @apply text-xl;
  }

  label, a {
    @apply text-dark;
  }
}

.hide {
  @apply translate-y-96;
  @apply opacity-0;
}

.show {
  @apply translate-y-0;
  @apply opacity-100;
  @apply transition-all duration-300 ease-nav;
}

.closer {
  @apply absolute;
  @apply right-[30px];
  @apply md:right-0;
  @apply top-[-50px];
  @apply cursor-pointer;
  @apply font-black;
}