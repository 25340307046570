.hamburger {
  @apply inline-block;
  @apply cursor-pointer;
  @apply bg-transparent;
  @apply border-0;
  @apply m-0 p-0;
  @apply overflow-visible;
  @apply z-70;
  @apply leading-[0];
  @apply rotate-0;
  @apply relative;
  transition-property: opacity, filter, rotate;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  &.isActive {
    @apply rotate-90;
  }

  .burgerBox {
    @apply w-8 h-6;
    @apply inline-block;
    @apply relative;
  }

  .burgerInner {
    @apply top-1/2;
    @apply block;
    @apply -mt-[2px];
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &,
    &::before,
    &::after {
      @apply w-8 h-[3px];
      @apply rounded-md;
      @apply absolute;
      @apply opacity-100;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
      background: inherit;
    }

    &::before,
    &::after {
      @apply block;
      content: '';
      background: inherit;
    }

    &::before {
      @apply -top-[8px];
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    &::after {
      @apply -bottom-[8px];
      transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}

.isActive {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  .burgerInner {
    @apply rotate-45;
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      @apply top-0;
      @apply opacity-100;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }

    &::after {
      @apply bottom-0;
      @apply -rotate-90;
      transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
