.heading {
  @apply relative;
  @apply z-10;

  &:hover {
    .hash {
      @apply opacity-30 hover:opacity-60;
    }
  }
}

.hash {
  @apply absolute;
  @apply top-1/2 -translate-y-1/2;
  @apply -left-6;
  @apply pr-4;
  @apply opacity-0 hover:opacity-60;
  @apply cursor-pointer;
  @apply transition-all duration-444 ease-in-out;
}
