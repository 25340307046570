.main {
  // @apply overflow-x-hidden;
  // @apply z-[1];
}

.contentArea {
  @apply relative;
  @apply w-full h-full;
}

.transPane {
  @apply bg-dark;
  @apply fixed;
  @apply flex flex-col;
  @apply h-full w-full;
  @apply inset-0;
  @apply justify-center items-center;
  @apply text-left;
  @apply text-white;
  @apply z-100;

  h1 {
    @apply font-display;
    @apply font-black;
    @apply uppercase;

    line-height: 0.9;
  }

  span {
    @apply inline-block;
    @apply font-display;
    @apply font-black;
    @apply uppercase;
    @apply text-5xl md:text-8xl;

    line-height: 0.9;
  }
}

.textWrapper {
  @apply overflow-hidden;
  @apply block;
}

.trans {
  @apply absolute flex flex-col w-full;
  @apply overflow-hidden;
}

.slideUp {
  @apply bg-tertiary;

  div {
    @apply fixed;
    @apply left-0 top-0;
    @apply w-full h-screen;
    @apply bg-tertiary;
    @apply z-100;
    @apply bg-tertiary;
  }
}

.waves {
  @apply bg-dark;

  div {
    @apply fixed;
    @apply left-0 top-0;
    @apply w-full h-screen;
    @apply bg-tertiary;
    @apply z-100;

    &:nth-of-type(2) {
      @apply bg-primary;
    }

    &:nth-of-type(3) {
      @apply bg-dark;
    }

    &:nth-of-type(4) {
      @apply bg-white;
    }
  }
}

.sidePattern {
  @apply absolute flex flex-col-reverse;
  margin-top: 150px;
  padding-left: 30px;
  z-index: -1;

  svg path {
    transform: scale(2);
  }
}

.light {
  @apply bg-white;
  @apply text-dark;

  a{
    @apply text-dark;
  }
}
