.wrapper {
  @apply flex flex-row;
  @apply list-none;
  @apply gap-4;
  @apply block lg:hidden;
}

.navWrapper {
  @apply fixed;
  @apply bg-primary-fade;
  @apply right-0 top-0;
  @apply h-screen w-screen;
  @apply z-50;
  @apply flex flex-col;
  @apply justify-center;
  @apply m-0 p-0;

  ul {
    @apply flex flex-col;
    @apply gap-7;
    @apply items-center;
    @apply self-center;
    @apply list-none;
    @apply m-0 p-0;

    li {
      @apply text-dark;
      @apply text-4xl;

      a {
        @apply text-dark;
        @apply no-underline;

        @apply font-[600];
      }
    }
  }
}

.inactiveMenu {
  @apply -translate-y-full;
  @apply opacity-0;
}

.activeMenu {
  @apply -translate-y-0;
  @apply opacity-100;
}

.navWrapper.open {
  @apply translate-x-0;
  // @apply transition-transform duration-444 ease-nav;
}

.navWrapper.closed {
  @apply translate-x-full;
  // @apply transition-transform duration-444 ease-nav;
}

.navWrapper li {
  @apply text-5xl;
  @apply translate-x-screen;

  font-weight: var(--heading-font-weight);
  letter-spacing: var(--heading-letter-spacing);
}

.navWrapper.open li {
  @apply translate-x-0;
  // @apply transition-all duration-444 ease-nav;
}

// .navWrapper.open li:nth-of-type(1) {
//   @apply delay-[0.22s];
// }

// .navWrapper.open li:nth-of-type(2) {
//   @apply delay-[0.33s];
// }

// .navWrapper.open li:nth-of-type(3) {
//   @apply delay-[0.44s];
// }

// .navWrapper.open li:nth-of-type(4) {
//   @apply delay-[0.55s];
// }

// .navWrapper.open li:nth-of-type(5) {
//   @apply delay-[0.66s];
// }

// .navWrapper.open li:nth-of-type(6) {
//   @apply delay-[0.77s];
// }

.bagmanMobile{
  width: 180px
}