.wrapper {
  @apply flex flex-row;
  @apply list-none;
  @apply gap-4;
  @apply hidden lg:flex;
}

.navWrapper {
  @apply flex flex-row;
  @apply gap-4;
  @apply justify-center items-center;
  @apply m-0 p-0;
  @apply relative;
  @apply z-50;

  ul {
    @apply flex flex-row;
    @apply gap-6;
    @apply items-center;
    @apply self-center;
    @apply list-none;
    @apply m-0 p-0;

    li {
      @apply text-dark;
      @apply text-sm;
      @apply tracking-widest;

      a {
        @apply text-dark;
        @apply relative;
        @apply font-semibold;
        @apply leading-none;
        // @apply decoration-2 underline-offset-3;
        @apply relative;
        @apply z-[99999999];

        background: unset !important;
        // transition: all 0.5s ease;
        padding-bottom: 4px;

        &::after {
          content: '';
          @apply absolute;
          @apply w-full;
          @apply scale-x-0;
          @apply h-[2px];
          @apply left-0 bottom-0;
          @apply bg-black;
          @apply origin-bottom-right;
          transition: transform 0.4s ease;
        }

        &.navItem {
          &:hover {
            &::after {
              @apply scale-x-100;
              @apply origin-bottom-left;
            }

            &.killHover {
              pointer-events: none;

              &::after {
                @apply scale-x-0;
                @apply origin-bottom-left;
              }
            }
          }
        }
      }
    }
  }
}

.navItem {
  &:hover {
    > div {
      opacity: 1;
      visibility: visible;
      height: auto;
      // transition: all 0s ease;
    }
  }
}

.open {
  color: #000 !important;
}
