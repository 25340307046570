.wrapper {
  @apply block;
  @apply w-full;
  @apply rounded-3xl;
  @apply bg-pink-50;
  @apply my-8;
  
  .fade {
    @apply w-full h-full;
    @apply bg-white bg-opacity-80;
    @apply top-0 left-0;
    @apply flex;
    @apply justify-start items-center;
    @apply py-7 px-11;

    p {
      @apply m-0 p-0;
      @apply text-[15px];
      @apply leading-normal;
  
      margin-bottom: 0 !important;
  
      span {
        @apply font-bold;
        @apply mr-2;
      }
    }
  }
}
