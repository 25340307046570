.wrapper {
  @apply w-full;
  @apply p-4 xl:px-12;
  @apply bg-white;

  .container {
    @apply max-w-wrap;
    @apply mx-auto;
    @apply p-3 sm:p-5 xl:p-10;
    @apply w-full;
    @apply flex flex-col;
    @apply gap-4 md:gap-12;

    * {
      @apply text-dark;
    }

    .row {
      @apply w-full;
      @apply flex flex-col md:flex-row;
      @apply items-start;
      @apply gap-20 sm:gap-10 lg:gap-36;

      & > div {
        &:not([data-flex='false']) {
          @apply md:flex-1;
        }
      }

      &:last-of-type {
        @apply flex-row;
        @apply items-center;
      }
    }

    .divider {
      @apply w-full;
      @apply h-[2px];
      @apply bg-dark bg-opacity-10;
      @apply w-screen;
      @apply relative;
      @apply left-1/2 right-1/2;
      @apply -ml-[50vw] -mr-[50vw];
    }
  }
}
