.wrapper {
  @apply hidden md:block;

  h5,
  h6,
  .heading {
    @apply text-lg;
    @apply font-semibold;
    @apply leading-tight;
    @apply mt-0 mx-0 mb-2;
    @apply p-0;
  }

  ul {
    @apply m-0 p-0;
    @apply w-full;

    li {
      @apply m-0 p-0;
      @apply w-full;

      a {
        @apply m-0 p-0;
        @apply text-sm;
        @apply relative;
        padding-bottom: 4px;
        transition: all 0.5s ease;

        &::after {
          content: '';
          @apply absolute;
          @apply w-full;
          @apply scale-x-0;
          @apply h-[2px];
          @apply left-0 bottom-0;
          @apply bg-black;
          @apply origin-bottom-right;
          transition: transform 0.4s ease;
        }

        &:hover {
          &::after {
            @apply scale-x-100;
            @apply origin-bottom-left;
          }
        }
      }
    }
  }
}
