.wrapper {
  @apply my-6 sm:my-10;

  &.expanded {
    @apply w-screen sm:w-full;
    @apply relative;

    @apply bg-black bg-opacity-5;
    @apply mx-auto;
    @apply py-8 px-8 sm:py-20 sm:px-24;

    @apply relative;
    @apply left-1/2 sm:left-auto;
    @apply right-1/2 sm:right-auto;
    
    @apply -ml-[50vw] sm:ml-0;
    @apply -mr-[50vw] sm:mr-0;

    & > div {
      @apply block;

      img {
        @apply w-full;
        @apply m-0 p-0;
      }
    }

    figcaption {
      @apply mt-2;
      @apply text-xs;
      @apply text-dark text-opacity-50;
      @apply hidden sm:block;
    }
  }

  a {
    @apply no-underline;
  }
}
