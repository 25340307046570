.wrapper {
  @apply w-full;
  @apply p-4 xl:px-12;
  @apply fixed;
  @apply top-0 left-0;
  @apply z-[200];

  // comment out for now, bg colour option with image overlay
  &.article {
    .container {
      @apply pb-0;
    }
  }
}

.container {
  @apply max-w-wrap;
  @apply mx-auto;
  @apply p-3 sm:p-5 xl:p-10;
  @apply rounded-xl;
  @apply w-full;

  @apply flex flex-row;
  @apply items-center justify-between;

  a,
  li,
  button {
    @apply uppercase;
    @apply font-medium;
  }

  h1 {
    line-height: 0.9;
    @apply font-display;
    @apply font-black;
    @apply uppercase;
    @apply text-[2.5rem];
    @apply inline-flex flex-wrap;
    gap: 0 20px;
  }

  h4 {
    @apply font-body;
    @apply font-medium;
    @apply text-base;
    @apply mt-0;
  }
}

// Logo
.logo {
  @apply relative;
  @apply w-12 sm:w-14 xl:w-16;
  @apply flex;
  @apply justify-start items-center;
  @apply z-[99999999];

  svg {
    @apply m-0 p-0;
    @apply w-full;
  }
}

.button {
  @apply relative;
  @apply w-12 sm:w-14 xl:w-16;
  @apply hidden lg:block;

  button {
    position: absolute !important;
    @apply right-0 top-1/2;
    @apply -translate-y-1/2;
  }
}
