.menu {
  @apply w-full;
  @apply mt-0;
}

.formItem {
  @apply flex flex-col;
  @apply mb-8;
}

.formItem label {
  @apply font-body;
  /* @apply text-dark; */
  @apply text-[rgba(0,0,0,.3)];
  @apply font-medium;
  @apply mb-2;
  @apply text-base;
  @apply normal-case;
}

.formItem input,
.formItem textarea,
.formItem select {
  @apply w-full;
  resize: none;
  @apply border-0;
  @apply border-b border-black;
  @apply bg-transparent;
  @apply text-dark;
  @apply outline-none;
  @apply py-4;
  @apply text-base;
  @apply font-body;
  transition: 0.2s ease-in;

  &.menu {
    @apply border-b border-solid border-black;
    @apply bg-white;
    @apply text-black;
    @apply p-0;
  }
}

.formItem input.menu:focus,
.formItem textarea.menu:focus,
.formItem select.menu:focus {
  /* @apply; */
}

.formItem .textArea {
  font-size: 20px;
  font-weight: 500;
  min-height: 320px;
}

.formItem textarea {
  height: 8rem;
}