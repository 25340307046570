.wrapper {
  @apply flex flex-col;
  @apply gap-4;

  p {
    @apply m-0 p-0;
    @apply w-full;
    @apply text-lg md:text-xl;
    @apply font-bold;
    @apply leading-7;

    a {
      @apply border-b-2 border-black;
    }
  }

  address {
    @apply text-sm;
    @apply not-italic;
  }
}
