.wrapper {
  @apply w-full;
  @apply my-8 mx-auto;
  @apply max-w-wrap;
  @apply px-9;
  @apply xl:px-[5.5rem];
  @media (min-width: 1750px) {
    @apply px-[2.5rem];
  }

  &.menu{
    @apply pl-0;
    @apply my-0;
  }
}

.button {
  @apply text-right;
}

.button button {
  font-size: 1.3rem;
  padding: 1rem 4rem;
  margin-right: auto;
}

.split {
  @apply grid;
  @apply grid-cols-1;
  @apply xl:grid-cols-2;
  gap: 2rem;
}
