.wrapper {
  @apply flex flex-col;

  .imageContainer {
    @apply relative;
    
    @apply aspect-square;
    
    img {
      @apply object-cover;
    }
  }
  
  .textContainer {
    @apply mt-4;

    span {
      @apply font-display;
      @apply font-bold;
      @apply text-xl;
      @apply uppercase;
      @apply m-0 p-0;
    }

    h4 {
      @apply normal-case;
      @apply font-body;
      @apply font-normal;
      @apply text-sm;
      @apply m-0 p-0;
    }
  }
}