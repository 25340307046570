.wrapper {
  @apply w-full;
  @apply flex flex-col md:flex-row;
  @apply justify-between;
  @apply gap-3;

  & > div {
    @apply flex-1;

    h5,
    p {
      @apply text-lg;
      @apply font-semibold;
      @apply leading-tight;
      @apply mt-0 mx-0 mb-2;
      @apply p-0;
    }

    ul {
      @apply m-0 p-0;
      @apply w-full h-full;
      @apply overflow-hidden md:overflow-visible;
      @apply md:max-h-fit;

      li {
        @apply m-0 p-0;
        @apply w-full;

        a {
          @apply m-0 p-0;
          @apply text-sm;
          @apply relative;
          transition: all 0.5s ease;
          padding-bottom: 4px;

          &::after {
            content: '';
            @apply absolute;
            @apply w-full;
            @apply scale-x-0;
            @apply h-[2px];
            @apply left-0 bottom-0;
            @apply bg-black;
            @apply origin-bottom-right;
            transition: transform 0.4s ease;
          }

          &:hover {
            &::after {
              @apply scale-x-100;
              @apply origin-bottom-left;
            }
          }
        }
      }
    }
  }
}
