.button {
  @apply cursor-pointer;
  @apply font-body;
  @apply py-4 px-4;
  @apply rounded-full;
  @apply text-sm;
  @apply transition-all duration-300 ease-in-out;
  @apply uppercase;
  @apply leading-[1];
  @apply no-underline;
  @apply border-0;

  // Fade
  .fade {
    @apply absolute;
    @apply w-full h-full;
    @apply top-0 left-0;
    @apply bg-white bg-opacity-80;

    & + * {
      @apply text-dark;
    }
  }

  * {
    @apply no-underline;
    @apply border-0;
  }

  // Movement
  &.static {
    @apply font-bold;
  }

  &.scrolling {
    @apply relative;
    @apply overflow-hidden;
    @apply w-fit;
    @apply max-w-[175px];
    @apply px-0;

    & > div,
    .marquee-container {
      @apply rounded-full;
      @apply w-full;
    }

    span {
      @apply uppercase;
      @apply font-bold;
      @apply no-underline;
      @apply border-0;

      &:last-of-type {
        @apply mr-5;
      }
    }
  }

  // Colours
  &.light {
    @apply bg-white hover:bg-tertiary;
    @apply text-dark;

    &.outline {
      @apply border border-black border-opacity-100 hover:border-opacity-0;
    }
  }

  &.dark {
    @apply bg-dark hover:bg-primary;
    @apply text-white hover:text-dark;

    &.outline {
      @apply border border-white border-opacity-100 hover:border-opacity-0;
    }
  }

  &.contrast {
    @apply bg-tertiary hover:bg-white;
    @apply text-dark;
  }
}

.marquee {
  @apply relative;
  @apply overflow-hidden;
  @apply w-full;
  @apply flex;
}

.track {
  white-space: nowrap;
  will-change: transform;
  animation: marqueeLoop 5s linear infinite forwards;

  span {
    margin-right: 10px;
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes marqueeLoop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
