.wrapper {
  @apply fixed;
  @apply left-0;
  @apply w-full h-0;
  @apply p-8;
  @apply flex flex-row;
  @apply justify-center;
  @apply bg-white;
  @apply opacity-0;

  top: var(--header-height);

  transform-origin: top center;
  transition: all 0s ease;
  visibility: hidden;

  .container {
    height: var(--header-height);
    @apply w-full;
  }

  &::after {
    content: '';
    @apply absolute inset-0;
    @apply bg-white;
    @apply z-[-1];
    @apply top-[-100px];
    transition: all 0s ease;
  }
}

.subNavWrapperActive {
  @apply opacity-100;
  @apply font-black;

  transform: scaleY(1);
  transition: opacity 0.33s var(--smooth) 0.11s, transform 0s linear;
}

.subNavWrapper ul {
  @apply flex;
  @apply flex-col;
  @apply gap-0;
}

.subNavWrapper li {
  @apply py-0;
}

.subNavContentImageWrapper {
  @apply relative;
  @apply w-full;
  @apply h-32;
  @apply overflow-hidden;
}

.subNavContentImageWrapper img {
  @apply absolute;
  @apply w-full;
  @apply h-full;
  @apply object-cover;
  @apply object-center;
}

.subItemLinks li {
  transition: all 0.5s ease;
}
.subItemLinks li:hover {
  color: #f16521;
  transition: all 0.5s ease;
}

.innerSubNav {
  @apply flex;
  @apply gap-[80px];
  @apply max-w-wrap;
  @apply mx-auto;
  @apply pt-14 pb-14 pl-14 pr-0;

  &.innerContact{
    @apply gap-0;
  }

  .description {
    flex: 1;

    h3 {
      @apply text-xl;
      @apply mt-0;
      @apply normal-case;
      @apply font-display;
    }

    p {
      @apply max-w-[65%];
      @apply mb-0;
      @apply normal-case;
      @apply font-normal;
    }

    span {
      @apply normal-case;
    }
  }


  .contentContainer{
    flex: 2;
    @apply flex flex-col;
    @apply gap-4;
    @apply relative;
    @apply overflow-hidden;
    @apply right-[-50px];

    form{
      @apply max-w-[80%];
    }

    &.fitted {
      @apply right-0;
    }

    h4 {
      @apply mb-0 mt-3;
      @apply text-[14px];
      @apply normal-case;
      line-height: 1.2;
    }

    a {
      @apply text-[14px];
      margin-top: auto;
      transition: all 0.5s ease;
      padding-bottom: 4px;
      @apply relative;

      &::after {
        content: '';
        @apply absolute;
        @apply w-full;
        @apply scale-x-0;
        @apply h-[2px];
        @apply left-0 bottom-0;
        @apply bg-black;
        @apply origin-bottom-right;
        transition: transform 0.4s ease;
      }

      &:hover {
        &::after {
          @apply scale-x-100;
          @apply origin-bottom-left;
        }
      }
    }
   
  }

  .content {
    flex: 2;
    @apply flex;
    @apply gap-4;
    @apply relative;
    @apply overflow-hidden;
    @apply right-[-50px];

    &.fitted {
      @apply right-0;
    }

    h4 {
      @apply mb-0 mt-3;
      @apply text-[14px];
      @apply normal-case;
      line-height: 1.2;
    }

    a {
      @apply text-[14px];
      margin-top: auto;
      transition: all 0.5s ease;
      padding-bottom: 4px;
      @apply relative;

      &::after {
        content: '';
        @apply absolute;
        @apply w-full;
        @apply scale-x-0;
        @apply h-[2px];
        @apply left-0 bottom-0;
        @apply bg-black;
        @apply origin-bottom-right;
        transition: transform 0.4s ease;
      }

      &:hover {
        &::after {
          @apply scale-x-100;
          @apply origin-bottom-left;
        }
      }
    }
  }
}

.clickThrough {
  // flex: 1;
  @apply flex;
  @apply mt-3;
  width: fit-content;

  &.workClick{
    &::after {
      // bottom: 13px !important;
    }
  }
}

.infoContainer {
  @apply mt-3;
  @apply flex flex-col;
  flex: 1;
}

.navItem {
  flex: 1;
  @apply h-full;
  @apply flex flex-col;

  span {
    @apply normal-case;
  }
}

.navCard {
  @apply relative;
  @apply h-52;
  @apply max-w-[208px];

  img {
    @apply object-cover;
  }
}


.imageLink{
  display: contents;

 &::after{
   display: none;
 }
}