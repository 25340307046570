form {
  .inputGroup {
    @apply relative;
    @apply w-full;
    @apply py-2 m-0 my-4;
    @apply flex flex-row;
    @apply border-0 border-b-2 border-black border-opacity-10;

    input[type='email'] {
      @apply w-full;
      @apply outline-none;

      &::placeholder {
        @apply text-gray-300;
      }
    }

    button {
      @apply w-full max-w-[80px];
      @apply cursor-not-allowed;

      &:not([disabled]) {
        @apply cursor-pointer;

        svg {
          @apply opacity-60;
        }

        :hover {
          svg {
            @apply opacity-100;
          }
        }
      }

      svg {
        @apply w-2/3;
        @apply opacity-20;
        @apply mx-auto;
        @apply transition-opacity duration-300 ease-in-out;
      }
    }
  }

  .label {
    // @apply m-0 p-0 pl-6 xl:pl-8;
    @apply w-full;
    @apply text-xs sm:text-sm;
    @apply relative;


    >span{
      @apply block ml-6;
    }

    input[type='checkbox'] {
      @apply absolute;
      @apply opacity-0;
      @apply w-0;
      @apply h-0;

      &:checked + span {
        &:before {
          @apply bg-dark;
        }
      }

      & + span {
        &:before {
          @apply w-3 h-3;
          @apply rounded-full;
          @apply border border-dark;
          @apply block;
          @apply absolute;
          @apply left-0 top-1/2;
          @apply -translate-y-1/2;
          @apply cursor-pointer;

          content: '';
        }
      }
    }

    a {
      @apply underline;
    }
  }
}
