.title{
  margin-bottom: 2rem !important;
}

.item {
  // border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  // padding: 1rem;

  // & + div {
  //   @apply w-full;
  //   // @apply bg-black bg-opacity-10 hover:bg-opacity-20;
  //   @apply m-0;
  //   @apply py-2 px-4;
  //   @apply cursor-pointer;
  //   @apply flex flex-row;
  //   @apply justify-between items-center;
  // }
}

.tab{
  @apply flex justify-between;
  @apply cursor-pointer;

  h3{
    margin: 0 !important;
    font-size: 18px !important;
  }
}